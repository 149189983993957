import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import store from './store'
import axios from  './config/axios';

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$store = store


if(localStorage.getItem('token')) {
  if(store.getters.isExpired) {
    store.dispatch('refreshToken')
  }else{
    store.commit('setToken', {
      token: localStorage.getItem('token')
    })
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.authToken
    store.commit('setExpiredIn', {
      expiredIn: localStorage.getItem('expiredIn')
    })
    store.commit('setRefreshToken', {
      refreshToken: localStorage.getItem('refreshToken')
    })
    store.dispatch('getProfile')
  }
}

if(localStorage.getItem("companiesId")){
  axios.defaults.headers.common['Companies-Id'] = localStorage.getItem("companiesId");
}
if(localStorage.getItem('openDate')) {
 store.commit('setOpenDate', { openDate: localStorage.getItem('openDate') })
 store.commit('setCloseDate', { closeDate: localStorage.getItem('closeDate') })
 store.commit('setCaja', { caja: localStorage.getItem('caja') })
}



router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.authToken) next({ name: 'login' })
  else next()
})
new Vue({
  router,
  store,
  render: h => h(App)

}).$mount('#app')
