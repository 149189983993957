<template>
  <div>
    <b-modal v-model="show" id="bv-modal-example" hide-footer>
      <template #modal-title> Cambiar Fechas </template>
      <div class="d-block text-center">
        <form>
          <div class="form-group">
            <label for="">Fecha de apertura</label>
            <input type="date" v-model="openDate" class="form-control" />
          </div>
          <div class="form-group">
            <label for="">Fecha de cierre</label>
            <input type="date" class="form-control" v-model="closeDate" />
          </div>
          <div class="form-group">
            <label for="">Caja</label><br />
            <select
              v-model="caja"
              class="form-control"
              aria-label="Default select example"
            >
              <option selected :value="getAllCajas">Todas</option>
              <option
                :selected="caja == value"
                endif
                v-for="(value, key) in cajas"
                :key="key"
                :value="value"
              >
                {{ value }}
              </option>
            </select>
          </div>
        </form>
      </div>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="changeDates"
        >Aceptar</b-button
      >
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  props: {
    eventShowModal: () => {},
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openDate: "",
      closeDate: "",
      cajas: new Array(),
      caja: "",
    };
  },
  methods: {
    changeDates() {
      this.$store.commit("setOpenDate", { openDate: this.openDate });
      this.$store.commit("setCloseDate", { closeDate: this.closeDate });
      this.$store.commit("setCaja", { caja: this.caja });
      localStorage.setItem("openDate", this.openDate);
      localStorage.setItem("closeDate", this.closeDate);
      localStorage.setItem("caja", this.caja);
      this.$emit("eventShowModal", false);
      this.getCajas();
      document.querySelector("#sidebar").classList.remove("active");
    },
    getCajas() {
      axios
        .get("/api/reports/cajas")
        .then((response) => {
          this.cajas = [];
          response.data.data.cajas.forEach((caja) => {
            this.cajas.push(caja.name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.openDate = this.getOpenDate;
    this.closeDate = this.getCloseDate;
    this.getCajas();
  },
  computed: {
    show: {
      get: function () {
        return this.getShowModal;
      },
      set: function (value) {
        this.$emit("eventShowModal", value);
      },
    },
    getAllCajas() {
      return this.cajas.join(",");
    },
    ...mapGetters(["getOpenDate", "getCloseDate", "getShowModal"]),
  },
};
</script>