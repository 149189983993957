<template>
  <section class="main-view">
    <div class="container-scroller">
      <Header />
      <div class="container-fluid page-body-wrapper">
        <Sidebar />
        <div class="main-panel">
          <div class="content-wrapper">
            <div class="row justify-content-center">
              <div class="col-8 p-1">
                <div class="alert alert-danger" role="alert" v-if="getStatus">
                  Esta empresa no esta disponible, por favor contacte a su
                  vendedor
                </div>
              </div>
            </div>
            <router-view></router-view>
          </div>
          <!-- content wrapper ends -->
          <Footer />
        </div>
        <!-- main panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
    <AlertPwa />
  </section>
</template>

<script>
import Header from "./partials/Header";
import Sidebar from "./partials/Sidebar";
import Footer from "./partials/Footer";
import AlertPwa from "@/components/alerts/install-pwa";
import { mapGetters } from "vuex";

export default {
  name: "layout",
  components: {
    Header,
    Sidebar,
    Footer,
    AlertPwa,
  },
  computed: {
    ...mapGetters(["getStatus"]),
  },
};
</script>