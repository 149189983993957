<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <a href="javascript:void(0);" class="nav-link">
            <div class="nav-profile-image">
              <img src="@/assets/images/faces/pngwing.com.png" alt="profile" />
              <span class="login-status online"></span>
            </div>
            <div class="nav-profile-text d-flex flex-column">
              <span class="font-weight-bold mb-2">{{ getFullName }} </span>
              <span class="text-secondary text-small">{{
                getCompany.name
              }}</span>
            </div>
            <i
              class="mdi mdi-bookmark-check text-success nav-profile-badge"
            ></i>
          </a>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <span class="menu-title">Dashboard</span>
            <i class="mdi mdi-home menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/open-tables">
            <span class="menu-title">Facturas Abiertas</span>
            <i class="mdi mdi-progress-alert menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/sales">
            <span class="menu-title">Facturas</span>
            <i class="mdi mdi-sale menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/cancelled-sales">
            <span class="menu-title">Anulaciones</span>
            <i class="mdi mdi-delete menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <a class="nav-link" @click="changeCompany()">
            <span class="menu-title">Cambiar Sucursal</span>
            <i class="mdi mdi-office-building menu-icon"></i>
          </a>
        </li>
        <!-- <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <span class="menu-title">Productos</span>
            <i class="mdi mdi-store menu-icon"></i>
          </router-link>
        </li> -->
        <li class="nav-item" v-on:click="collapseAll">
          <a @click="eventShowModal(true)" class="nav-link">
            <span class="menu-title">Cambiar fechas</span>
            <i class="mdi mdi-calendar menu-icon"></i>
          </a>
        </li>
      </ul>
    </nav>
    <open-date
      :showModal="showModal"
      @eventShowModal="eventShowModal"
    ></open-date>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import OpenDate from "../../components/modals/opendate.vue";
export default {
  name: "sidebar",
  components: {
    OpenDate,
  },
  data() {
    return {
      collapses: [{ show: false }, { show: false }, { show: false }],
      showModal: false,
    };
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    eventShowModal(value) {
      this.$store.commit("setShowModal", { showModal: value });
    },
    changeCompany() {
      localStorage.removeItem("companiesId");
      this.$router.push("/companies-select");
    },
  },
  computed: {
    ...mapGetters(["getFullName", "getOpenDate", "getCompany"]),
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  watch: {
    $route() {
      if (document.querySelector("#sidebar").classList.contains("active")) {
        document.querySelector("#sidebar").classList.remove("active");
      }
    },
  },
};
</script>