const state = () => ({
    openDate: '',
    closeDate: '',
    caja: '',
    showModal: false,
})

// getters
const getters = {
    getOpenDate: state => state.openDate,
    getCloseDate: state => state.closeDate,
    getShowModal: state => !state.openDate.length ? true : state.showModal,
    getCaja: state => state.caja,
}

// actions
const actions = {

}

// mutations
const mutations = {
    setOpenDate(state, payload) {
        state.openDate = payload.openDate
    },
    setCloseDate(state, payload) {
        state.closeDate = payload.closeDate
    },
    setShowModal(state, payload) {
        state.showModal = payload.showModal
    },
    setCaja(state, payload) {
        state.caja = payload.caja
    }
}

export default {
  state,
  getters,
  actions,
  mutations
}