import moment from 'moment'
import axios from 'axios'
import router from '@/router'
import amplitud from 'amplitude-js'

const state = () => ({
  user: {
    companies: []
  },
  auth: {
    token: null,
    expiredIn: null,
    refreshToken: null
  },
  companies: [],
  status: 0
})

// getters
const getters = {
    authToken: state => state.auth.token,
    authExpiredIn: state => state.auth.expiredIn, 
    isExpired: state => {
      if (state.auth.expiredIn) {
        return moment().isAfter(moment(state.auth.expiredIn))
      }
      return false
    }, 
    getFullName: state => {
      return state.user ? state.user.first_name + ' ' + state.user.last_name : ''
    },
    getFirstName: state => state.user ?  state.user.first_name: '',
    getLastName: state => state.user ? state.user.last_name: '',
    getEmail: state => state.user ? state.user.email: '',
    company_name: state => state.user ? state.user.company.name : '',
    getUser: state => state.user,
    getCompanies: state => state.user.companies,
    getStatus: state => state.status,
    getCompany: state => {
      let company =  state.user.companies.filter(company => {
        return company.id == localStorage.getItem('companiesId')
      });
      return company[0]
    }
}

// actions
const actions = {
  getProfile() {
    axios.get('/api/users/profile').then(res => {
      this.commit('setUser', {"user":res.data})
    })
  },
  refreshToken() {
    this.logout()
  },
  
  logout() {
    localStorage.clear();
    this.commit('setToken', {token: ''})
    this.commit('setExpiredIn', {token: null})
    this.commit('setRefreshToken', {token: null})
    router.push('/auth-pages')
  },
  setEvent(context, payload) {
     amplitud.getInstance().init( process.env.VUE_APP_AMPLITUD_ID, context.state.user.email); // initializes client with the given userId
     amplitud.logEvent(payload.name);
     console.log("Hola")
  },
  checkStatus(context,companiesId) {
    axios
      .get("/api/reports/cajas", {
        headers: {
          "Companies-Id": companiesId,
        },
      })
      .then(() => {
        localStorage.setItem("companiesId", companiesId);
        router.push("/");
        axios.defaults.headers.common['Companies-Id'] = companiesId;
        context.commit('setStatus', {status: 0});
      });
  },
}

// mutations
const mutations = {
    setToken(state, payload) {
      state.auth.token = payload.token
    },
    setExpiredIn(state, payload) {
      state.auth.expiredIn = payload.expiredIn
    },
    setRefreshToken(state, payload) {
      state.auth.refreshToken = payload.refreshToken
    },
    setUser(state, payload) {
      state.user = payload.user
    },
    setCompaniesId(state, payload) {
      state.companiesId = payload.companiesId;
    },
    setStatus(state, payload) {
      state.status = payload.status
    }
}

export default {
  state,
  getters,
  actions,
  mutations
}