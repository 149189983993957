import axios from 'axios'
import store from '../store'
import router from '../router'
axios.defaults.showLoader = true;

axios.interceptors.request.use(
  config => {
      if (config.showLoader) {
          store.dispatch('loader/pending');
      }
      return config;
  },
  error => {
      store.dispatch('loader/done');
      return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    store.dispatch('loader/done');

    return response;
  },
  error => {
      // let response = error.response;
           console.log(error.response);
           if(error.response.status == 423) {
              store.commit('setStatus', {status:1});
           }
           if(error.response.status == 401) {
            router.push('/auth-pages/login');
          }
          
          if(error.response.status == 402) {
            router.push('/error-pages/error-402');
          }
         
           store.dispatch('loader/done');
      // // if (response.config.showLoader) {
      // // }

      return Promise.reject(error);
  }
)

axios.interceptors.request.use(request => {
  const token = store.getters.authToken;
  request.headers.common.Authorization =  'Bearer ' + token;

  return request;
});

export default axios;