<template>
  <div v-if="deferredPrompt">
    <div class="jumbotron">
      <h1 class="display-4">Hola</h1>
      <p class="lead">
        Te gustaria volver esta pagina una ap, solo tienes que hacer click en
        aceptar.
      </p>
      <hr class="my-4" />
      <p class="lead">
        <button class="btn btn-success btn-lg" @click="install" role="button">
          Aceptar
        </button>
        <button class="btn btn-danger btn-lg" @click="dismiss" role="button">
          Cancelar
        </button>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "install-pwa",
  data() {
    return {
      deferredPrompt: null,
    };
  },
  created() {
    navigator.serviceWorker.register("/service-worker.js", {
      scope: "/",
    });
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
  },
};
</script>