<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright ©  <a href="https://canelasoluciones.com/" target="_blank">Canela Soluciones</a> 2022.</span>
        <!-- <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Free <a href="https://www.bootstrapdash.com/vue-admin-templates/" target="_blank"> vue admin templates </a> from BootstrapDash.com</span> -->
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>